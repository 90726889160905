import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const DealersSection = () => {
  const data = useStaticQuery(graphql`
    query {
      dealershipPins: file(relativePath: { eq: "products/dealership-pins.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-dealers section__right-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Concessionárias <br className="is-hidden-mobile" />
          próximas
        </h2>
        <p className="subtitle is-hidden-desktop">
          Localize a unidade mais próxima de onde você estiver.
        </p>

        <div className="columns is-hidden-mobile">
          <div className="column is-half">
            <p className="subtitle">
              Precisa de alguma ajuda com o seu <br />
              carro? Localize a concessionária mais <br />
              perto de onde você estiver.
            </p>

            <p className="subtitle">
              Veja também todas as informações <br />
              de contato para falar diretamente <br />
              com a unidade mais próxima.
            </p>
          </div>
          <div className="column is-half">
            <div className="images">
              <img
                className="dealership-pins"
                src={data.dealershipPins.publicURL}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="images is-hidden-desktop">
          <img
            className="dealership-pins"
            src={data.dealershipPins.publicURL}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default DealersSection
